
interface InputHintsMobileProps {
    allOptions: any[]
}

export default function InputHintsMobile({allOptions}: InputHintsMobileProps) {
    return (
        <datalist>
            {allOptions.map((vl: string, i: number) =>
                <option value={vl}/>
            )}
        </datalist>
    )
}