import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import LoginRegister from "./user/login_register";
import Account from "./user/account";

interface MenuAppBarProps {
    storyConf: any[];
    usernameConf: any[];
}

export default function MenuAppBar({storyConf, usernameConf}: MenuAppBarProps) {
    const [open, setOpen] = useState(false);
    const [username, _] = usernameConf

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <div>
                        <Typography fontSize={15}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={toggleDrawer(true)}
                                color="inherit"
                            > Account
                                <AccountCircle/>
                            </IconButton>
                            {username ? "registered" : "not registered"}
                        </Typography>
                        <Typography variant="body2" color="text.primary" px={1}>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                {!username ?
                                    (<LoginRegister close={toggleDrawer(false)} storyConf={storyConf}
                                                    usernameConf={usernameConf}/>) : (
                                        <Account close={toggleDrawer(false)} storyConf={storyConf}
                                                 usernameConf={usernameConf}/>)
                                }
                            </Drawer>
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
