import Container from "@mui/material/Container";
import {useCallback, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface InputHintsProps {
    commandConf: any[];
    allOptions: any[]
}

export default function InputHintsComp({commandConf, allOptions}: InputHintsProps) {
    const [options, setOptions] = useState([])
    const [command, setCommand] = commandConf

    const filter_command = useCallback(() => {
        let vl: string
        const result = []
        for (vl of allOptions) {
            if (result.length >= 3) break;
            if (vl.includes(command.toLowerCase()) && vl) {
                result.push(vl);
            }
        }
        if (result.length !== 0) {
            //@ts-ignore
            setOptions(result)
        }
    }, [allOptions, setOptions, command]);


    const pressButton = (value: string) => {
        setCommand(value)
    }


    useEffect(() => {
        filter_command()
    }, [command, filter_command]);

    return (
        <Container>
            {options.map((value: string, i: number) =>
                <Button onClick={() => pressButton(value)}>
                    <Typography className={value} fontSize={13}>
                        {value}
                        <div/>
                    </Typography>
                </Button>
            )}
        </Container>
    )
}