import * as React from "react";
import {useState} from "react";
import {Box} from "@mui/material";
import TextField from "@mui/material/TextField";
import {getAPI, postAPI} from "../api";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {LogoutRounded} from "@mui/icons-material";

interface AccountProps {
    close: any;
    storyConf: any[]
    usernameConf: any[]
}

function Account({close, storyConf, usernameConf}: AccountProps) {
    const [newName, setNewName] = useState("")
    const [tz, setTz] = useState("")
    const [story, setStory] = storyConf
    const [username, setUsername] = usernameConf

    function save_change_of_account(): void {
        if (!newName)
            return;
        postAPI("change_username/", {"message": newName}).then();
        setUsername(newName)
        close();
    }

    function Logout() {
        getAPI("logout/").then();
        close();
        setStory([])
        setUsername("")
    }

    return (
        <Box sx={{backgroundColor: "background.paper"}}>
            <Typography px={10} py={5} alignItems="center">
                <Box>
                    <TextField label="change name" onChange={(e) => setNewName(e.target.value)}/>
                </Box>
                <Box>
                    <TextField label="your timezone" value={tz} onChange={(e) => setTz(e.target.value)}/>
                </Box>
                <Typography py={2}>
                    <Button variant="contained" onClick={save_change_of_account}>
                        Save
                    </Button>
                </Typography>
                <Typography>
                    <Button onClick={Logout} variant="outlined">
                        Logout to account
                        <LogoutRounded/>
                    </Button>
                </Typography>
            </Typography>
        </Box>
    )
}

export default Account;
