import * as React from 'react';
import {PaletteMode} from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import MenuAppBar from "../MenuAppBar";
import Help from "./help";


interface AppAppBarProps {
    mode: PaletteMode;
    toggleColorMode: () => void;
    storyConf: any[];
    usernameConf: any[];
}

function AppAppBar({mode, toggleColorMode, storyConf, usernameConf}: AppAppBarProps) {
    const [open, setOpen] = React.useState(false);
    // const [username, setUsername] = React.useState("")
    const [username, _] = usernameConf
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <div>
            <AppBar
                position="fixed"
                enableColorOnDark
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 'calc(var(--template-frame-height, 0px) + 28px)',
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '10px',
                            bgcolor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(255, 255, 255, 0.4)'
                                    : 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                    : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >

                            <Box>
                                <Typography variant="h6"
                                            sx={{fontSize: {sm: 30}}} color="text.primary" px={1} aria-selected={false}>
                                    My assistant
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{display: {xs: '10'}}}>
                            <Typography color="text.primary" px={5}>
                                {username ? username : "anonymous"}
                            </Typography>
                        </Box>

                        <Typography px={1}>
                            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode}/>
                        </Typography>

                        <Box
                            sx={{
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                size="small"
                                onClick={toggleDrawer(true)}
                                sx={{minWidth: '10px', p: '3px'}}>
                                <MenuIcon/>
                            </Button>
                        </Box>
                        <Box sx={{display: {sm: '', md: 'none'}}}>

                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '15dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            flexGrow: 1,
                                        }}>

                                        <MenuAppBar usernameConf={usernameConf} storyConf={storyConf}/>
                                        <Help/>

                                    </Box>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

export default AppAppBar;
