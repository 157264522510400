import {PaletteMode} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Chat from "./components/chat";
import AppAppBar from './components/AppAppBar';
// import Copyright from "./components/copyright";
import getLPTheme from './getLPTheme';
import {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import {getAPI} from "./api";
import Info from "./components/info";
import {unite} from "./auxiliaryFunc";

function App() {
    const last_theme: PaletteMode = localStorage.getItem("theme") === "light" ? "light" : "dark";
    const [mode, setMode] = useState<PaletteMode>(last_theme)
    const [showCustomTheme, setShowCustomTheme] = useState(true);
    const LPtheme = createTheme(getLPTheme(mode));
    const defaultTheme = createTheme({palette: {mode}});
    const [story, setStory] = useState([])
    const [name, setName] = useState("")

    useEffect(() => {
        getAPI("get_store/").then(([ok, status, msg]) => {
            setStory(msg.messages);
        });
        getAPI('user/').then(([ok, status, msg]) => {
            setName(msg.first_name);
        });

    }, []);

    const toggleColorMode = () => {
        setMode((prev) => (prev === "dark" ? "light" : "dark"));
    };

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <Container maxWidth="lg"
                       component="main"
                       sx={{display: 'flex', flexDirection: 'column', my: 16, gap: 4}}>
                <CssBaseline/>
                <AppAppBar mode={mode} toggleColorMode={toggleColorMode} storyConf={[story, setStory]}
                           usernameConf={[name, setName]}/>
                <Chat storyConf={[story, setStory]} usernameConf={[name, setName]}/>
                {/*<Copyright/>*/}
                {/*<Info sec={1} text={"welcome, " + name}/>*/}
            </Container>
        </ThemeProvider>
    );
}

export default App;
