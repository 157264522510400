import Typography from "@mui/material/Typography";
import {FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as React from "react";
import {useState} from "react";
import {getAPI, postAPI} from "../api";
import {Login} from "@mui/icons-material";

interface LoginRegisterProps {
    close: any;
    storyConf: any[]
    usernameConf: any[]
}

function LoginRegister({close, storyConf, usernameConf}: LoginRegisterProps) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [load, setLoad] = useState(false)
    const [radio, setRadio] = useState("Register")
    const [err, setErr] = useState("")
    const [story, setStory] = storyConf
    const [username, setUsername] = usernameConf


    const register = () => {
        if (!email || !password) {
            setErr("Mail and password cannot be empty")
            return
        }
        setLoad(true)
        postAPI(radio.toLowerCase() + "/", {
            'username': email,
            'password': password,
            'tz': Intl.DateTimeFormat().resolvedOptions().timeZone
        }).then(([ok, status, results]) => {
            if (ok) {
                setErr("")
                setPassword("")
                setEmail("")
                getAPI('user/').then(([ok, status, msg]) => {
                    setUsername(msg.first_name)
                })
                getAPI("get_store/").then(([ok, status, msg]) => {
                    setStory(msg.messages);
                });
                close()
            } else {
                setErr(results.error.username)
            }
            setLoad(false)
        })
    }


    return (
        <Box sx={{backgroundColor: "background.paper"}}>
            <Typography px={15} py={2}>
                <FormControl>
                    <RadioGroup
                        defaultValue="Register"
                        name="radio-buttons-group"
                        onChange={(event) => setRadio(event.target.value)}
                        color="inherit"
                        aria-haspopup="true"
                    >
                        <FormControlLabel value="Login" control={<Radio/>}
                                          label="login to account"/>
                        <FormControlLabel value="Register" control={<Radio/>}
                                          label="register an account"/>
                    </RadioGroup>
                </FormControl>
            </Typography>
            <Box
                sx={{
                    minWidth: '20dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}>
                    <TextField label="Enter you email" value={email} helperText=" "
                               onChange={(e) => setEmail(e.target.value)}
                    >

                    </TextField>
                    <TextField label="Your password" type="password" value={password} helperText=" "
                               onChange={(e) => setPassword(e.target.value)}
                    >
                    </TextField>

                    <Button onClick={register}>
                        {radio}
                        <Login/>
                    </Button>
                </Box>
                {load && (<LinearProgress/>)}
                {err}
            </Box>
        </Box>
    )
}

export default LoginRegister