import {Box, List, ListItemText} from "@mui/material";
import * as React from "react";
import {useEffect, useRef} from "react";
import Typography from "@mui/material/Typography";

function Messages(props: any) {

    const scrollRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
        scrollRef.current?.scrollIntoView({behavior: "smooth"});
    });

    return (
        <Box id="messages-box" height="70vh" overflow="auto" py={5}>
            <List sx={{mb: 2}}>
                {props.story.map((message: any, _: any) =>
                    <Typography align={message.robot ? "right" : "left"}>
                        <ListItemText
                            primary={message.message}
                            secondary={message.robot ? "assistant" : "you"}
                        />
                    </Typography>
                )}
                <ul ref={scrollRef}/>
            </List>
        </Box>
    );
}

export default Messages;
