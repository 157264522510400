import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {useState} from "react";
import Drawer from "@mui/material/Drawer";

function Help() {
    const [open, setOpen] = useState(false)

    const statusDriver = () => {
        setOpen(!open)
    }


    return (
        <Box>
            <Typography py={2}>
                <Button onClick={statusDriver}>
                    Help
                </Button>
            </Typography>
            <Drawer anchor="right" open={open} onClose={statusDriver}>
                <Box sx={{backgroundColor: "background.paper"}}>
                    help text
                </Box>
            </Drawer>
        </Box>
    )
}

export default Help