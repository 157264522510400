// const BASE_URL = "http://127.0.0.1:8000/api/";
import {Alert} from "@mui/material";


let BASE_URL = "/api/";


function getCookie(name: string) {
    if (!document.cookie) {
        return '';
    }
    const xsrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));
    if (xsrfCookies.length === 0) {
        return '';
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

async function postAPI(command: string, list: any) {
    const response = await fetch(BASE_URL + command, {
        method: "POST",
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify(list),
    });

    let results: any;
    try {
        results = await response.json();
    } catch (e) {
    }
    return [response.ok, response.status, results];
}

async function getAPI(command: string) {
    const response = await fetch(BASE_URL + command, {
        credentials: 'include',
    });
    const results = await response.json();
    // console.log(results);
    return [response.ok, response.status, results];
}

function ErrorAlert(props: any) {
    return (
        <>
            {
                Object.keys(props.error.error).map((key) => (
                    <Alert severity="error">{key}: {props.error.error[key]}</Alert>
                ))
            }

        </>
    );
}

export {postAPI, getAPI, ErrorAlert};
