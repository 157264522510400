import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Messages from "./Messages";
import {getAPI, postAPI} from "../api";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Send} from "@mui/icons-material";
import Container from "@mui/material/Container";
import {unite} from "../auxiliaryFunc";
import InputHintsComp from "./inputHintsComp";
import InputHintsMobile from "./inputHintsMobule";


interface chatProps {
    storyConf: any[];
    usernameConf: any[];
}

export default function Chat({storyConf, usernameConf}: chatProps) {

    const [command, setCommand] = useState("")
    const [story, setStory] = storyConf;
    const [username, setUsername] = usernameConf;
    const [allOptions, setAllOptions] = useState([])
    const isMobile: boolean = window.screen.width <= 480

    useEffect(() => {
        if (allOptions.length === 0) {
            getAPI("get_option_ans/").then(([ok, status, msg]) => {
                if (ok) {
                    setAllOptions(msg.messages)
                }
            })
        }
    }, [allOptions, setAllOptions]);

    // setInterval(() => {
    //     if (username) {
    //         getAPI('send/').then(([ok, status, msg]) => {
    //             if (ok && msg.message) {
    //                 const response = [{message: msg.message, robot: true}];
    //                 //@ts-ignore
    //                 setStory(unite(story, response));
    //             }
    //         })
    //     }
    // }, 3000);

    const addMessage = () => {
        const text = command.trim();
        if (!text)
            return;
        postAPI('message/', {'message': text}).then(([ok, status, msg]) => {
            if (ok) {
                setStory(unite(story, msg))
            }
        })
    }
    const enterPress = () => {
        addMessage()
        setCommand("")
    }
    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            enterPress()
        }
    }

    return (
        <Container sx={{my: -11}}>
            <Box>
                <Messages story={story}/>
            </Box>
            <Box color="primary" component="form">
                <Typography align="center" sx={{}}>
                    <TextField
                        sx={{ml: 1, flex: 1, width: isMobile ? "auto" : "80%" }}
                        label="Send command"
                        multiline
                        maxRows={2}
                        value={command}
                        onKeyDown={keyPress}
                        variant="outlined"
                        onChange={(e) => setCommand(e.target.value)}/>

                    <Button onClick={enterPress} type="button" aria-label="send">
                        <Send/>
                    </Button>
                    {!isMobile ?
                        (command ? <InputHintsComp commandConf={[command, setCommand]} allOptions={allOptions}/> : <div/>)
                        :
                        (<InputHintsMobile allOptions={allOptions}/>)
                    }
                </Typography>
            </Box>
        </Container>
    );
}
